@font-face {
  font-family: "silkscreen";
  src: url("/slkscr.ttf");
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;500&display=swap");

#root {
  height: 100%;
}

html {
  background-color: #2b2e43;
  height: 100%;
}

body {
  margin: 0;
  font-family: "silkscreen", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* position: absolute; */
  height: 100%;
  /* width: 100%; */
}

* {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.canvasBtnSelected {
  background-color: #ffffff !important;
  color: #212439 !important;
}

.hideCavnasPreview {
  display: none;
}

.max-size-palette {
  margin: 3px !important;
}

.min-size-pallete {
  margin: 15px;
}

.max-size-palette .picker {
  height: 4vmin !important;
}

.max-size-palette .color {
  height: 4vmin !important;
  width: 4vmin !important;
}
