@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap);
@font-face {
  font-family: "silkscreen";
  src: url("/slkscr.ttf");
}

#root {
  height: 100%;
}

html {
  background-color: #2b2e43;
  height: 100%;
}

body {
  margin: 0;
  font-family: "silkscreen", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* position: absolute; */
  height: 100%;
  /* width: 100%; */
}

* {
  touch-action: manipulation;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.canvasBtnSelected {
  background-color: #ffffff !important;
  color: #212439 !important;
}

.hideCavnasPreview {
  display: none;
}

.max-size-palette {
  margin: 3px !important;
}

.min-size-pallete {
  margin: 15px;
}

.max-size-palette .picker {
  height: 4vmin !important;
}

.max-size-palette .color {
  height: 4vmin !important;
  width: 4vmin !important;
}

.home * {
  box-sizing: border-box;
}

.home {
  font-family: "Poppins", sans-serif;
}

.home h1 {
  font-size: 80px;
  line-height: 1;
}

.home h2 {
  font-size: 40px;
  line-height: 1.3;
}

.home h3 {
  font-size: 32px;
  line-height: 1.3;
  letter-spacing: 1px;
  font-weight: 600;
}

.home h4 {
  font-size: 24px;
  line-height: 28px;
}

.home h5 {
  font-size: 18px;
  line-height: 16px;
}

.home h6 {
  font-size: 15px;
  line-height: 22px;
  font-weight: 600;
}

.home p {
  font-size: 18px;
  line-height: 1.4;
  font-weight: 400;
  margin: 0;
  padding: 0;
  color: #000;
}

.home button {
  border: none;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
}

.home .btn {
  white-space: inherit;
}

.home a {
  font-size: 15px;
  font-weight: 400;
  transition: all 0.3s ease;
  color: #333;
}

.home a:hover,
.home a:active {
  outline: 0;
  text-decoration: none;
}

.home a:focus {
  text-decoration: none;
  outline: 0;
}

:focus {
  outline: 0;
}

.home ul {
  margin: 0;
  list-style-type: none;
}

::-webkit-input-placeholder {
  color: #000;
  text-transform: capitalize;
}

:-ms-input-placeholder {
  color: #000;
  text-transform: capitalize;
}

::-ms-input-placeholder {
  color: #000;
  text-transform: capitalize;
}

::placeholder {
  color: #000;
  text-transform: capitalize;
}

::-moz-placeholder {
  color: #000;
  text-transform: capitalize;
}

:-ms-input-placeholder {
  color: #000;
  text-transform: capitalize;
}

::-webkit-input-placeholder {
  color: #000;
  text-transform: capitalize;
}

.home ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/**/

.home p,
.home h1,
.home h2,
.home h3,
.home h4,
.home h5,
.home h6,
.home span,
.home a,
.home ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  color: #000;
}

.home a {
  display: block;
}

.home .text-center {
  text-align: center;
}

.home .text-left {
  text-align: left;
}

.home .text-right {
  text-align: right;
}

.home .worp-4k {
  max-width: 1920px;
  padding: 0 15px;
  margin: 0 auto;
  overflow: hidden;
}

.home .container-warp {
  max-width: 1410px;
  padding: 0 15px;
  margin: 0 auto;
}

.home .theme-btn {
  background: #f77758 none repeat scroll 0 0;
  color: #fff;
  display: inline-block;
  justify-content: center;
  padding: 14px 50px;
  text-align: center;
  font-size: 20px;
}

.home .theme-btn:hover {
  background: #fff none repeat scroll 0 0;
  color: #f77758;
}

.home .banner-area .shap1 {
  left: -110px;
  position: absolute;
  top: 0px;
  z-index: -1;
}

.home .banner-area .shap1 img {
  opacity: 0.6;
  width: 400px;
}

.home .filter-area .shap1 {
  left: -110px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.home .home .filter-area .shap1 img {
  opacity: 0.6;
  width: 380px;
}

.home .filter-area .shap2 {
  position: absolute;
  right: 0;
  width: 350px;
  top: -3px;
  z-index: -1;
}

.home .graphic-arer .shap2 {
  position: absolute;
  left: -60px;
  bottom: -12px;
  z-index: -1;
}

.home .graphic-arer .shap2 img {
  width: 390px;
}

.home .store-work-arer .shap1 {
  position: absolute;
  z-index: -1;
  bottom: 20%;
  left: -150px;
}

.home .store-work-arer .shap1 img {
  width: 390px;
}

.home .store-work-arer .shap2 {
  position: absolute;
  z-index: -1;
  bottom: 8%;
  right: -150px;
}

.home .store-work-arer .shap2 img {
  width: 390px;
}

.home .beginning-arer .shap2 {
  position: absolute;
  z-index: -1;
  bottom: -70px;
  left: -150px;
}

.home .beginning-arer .shap2 img {
  width: 410px;
}

/********************* Banner Area ************************/

.home .banner-area {
  overflow-x: hidden;
  margin: 0;
  height: 100%;
  background: #2b2e43;
}

.home .banner-content-area {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.home .banner-content-area {
  height: 750px;
  position: relative;
  z-index: 9;
  margin-top: 100px;
}

.home .banner-content {
  max-width: 660px;
  margin-top: 60px;
}

.home .banner-img {
  left: 0;
  margin-left: 610px;
  position: absolute;
  top: 50px;
  z-index: -1;
}

.home .banner-img img {
  width: 1300px;
}

.home .banner-content h1 {
  color: #fff;
  font-size: 114px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 50px;
}

.home .banner-content h1 br {
  display: none;
}

.home .banner-content h6 {
  color: #f77758;
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.home .pixelchain {
  width: 128px;
  height: 128px;
}

@media only screen and (max-width: 1999px) {
  .home .worp-4k {
    overflow: hidden;
  }
}

@media only screen and (max-width: 1366px) {
  .home .banner-content {
    max-width: 460px;
  }

  .home .banner-content h1 {
    font-size: 78px;
    font-weight: 700;
  }

  .home .banner-content h6 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1199px) {
  .home .banner-area {
    padding-top: 90px;
  }

  .home .banner-content-area {
    margin-top: 0;
  }

  .home .banner-content-area {
    height: 530px;
  }

  .home .banner-img {
    margin-left: 360px;
  }

  .home .banner-img img {
    width: 830px;
  }

  .home .banner-content {
    max-width: 400px;
    margin-top: 0;
  }

  .home .banner-content h1 {
    font-size: 60px;
  }

  .home .st-work-content h2 {
    font-size: 40px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .home .banner-content-area {
    display: inherit;
    height: 580px;
    text-align: center;
  }

  .home .banner-img {
    margin-left: 0;
    position: inherit;
    top: 40px;
  }

  .home .banner-content {
    max-width: 250px;
    margin-top: 0;
    margin: auto;
  }

  .home .banner-img img {
    width: 446px;
  }

  .home .banner-content h1 {
    font-size: 38px;
    line-height: 1.4;
    margin: 10px 0 30px;
    font-weight: 700;
  }
  .home .banner-content h1 br {
    display: inherit;
  }
  .home .theme-btn {
    padding: 12px 20px;
    font-size: 12px;
  }

  .home .banner-content h6 {
    font-size: 12px;
  }

  .home .banner-area .shap1 {
    left: -206px;
    top: 120px;
  }
}

/****************** Filter Area ************************/

.home section.filter-area {
  padding-bottom: 120px;
  text-align: center;
}
/* Style the tab */
.home .tab {
  overflow: hidden;
  text-align: center;
  margin: 100px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Style the buttons inside the tab */
.home .tab button {
  background-color: transparent;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  padding: 0 20px;
  cursor: pointer;
  color: #fff;
  font-size: 23px;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
}

.home .tab button:hover,
.tab button.active {
  color: #f77758;
}

.home .tabcontent {
  margin-bottom: 5rem;
}

.home .ds-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(128px, 1fr));
  width: 100%;
  grid-gap: 1rem;
  justify-items: center;
}

@media only screen and (max-width: 767px) {
  .home section.filter-area {
    padding-bottom: 0;
  }

  .home section.filter-area .worp-4k {
    padding-bottom: 60px;
    padding-top: 50px;
  }

  .home .mo-none {
    display: none;
  }

  .home .filter-area .shap2 {
    right: 95px;
    width: 150px;
    top: 40px;
  }

  .home .filter-area .shap1 {
    display: none;
  }

  .home .tab {
    margin: 0px auto 50px;
  }

  .home .tab button {
    padding: 0 10px;
    font-size: 18px;
  }
  .home .ds-grid {
    margin-bottom: 30px;
  }
}

/*************** Graphic Area *****************/

.home .graphic-items {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

.home .graphic-item {
  background: #fff;
  padding: 40px 40px 60px;
  width: 32%;
}

.home .graphic-item.grit-center {
  background: #f77758;
}

.home .graphic-img {
  margin-bottom: 100px;
}

.home .graphic-content {
  position: relative;
}

.home .graphic-content::after {
  background: #f77758;
  content: "";
  height: 50px;
  left: -40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
}

.home .graphic-item.grit-center .graphic-content::after {
  background: #fff;
}

.home .graphic-item.grit-center p {
  color: #fff;
}

.home .gr-all p {
  color: #a8a8a8;
  font-size: 22px;
  margin-bottom: 40px;
}

.home .gr-all {
  margin-top: 120px;
  text-align: center;
}

@media only screen and (max-width: 1199px) {
  .home .graphic-content p {
    font-size: 16px;
  }

  .home .graphic-item {
    padding: 30px 10px 50px 20px;
  }

  .home .graphic-content::after {
    left: -20px;
  }

  .home .graphic-img {
    margin-bottom: 90px;
  }

  .home .graphic-img img {
    width: 30%;
  }

  .home .gr-all {
    margin-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .home .gr-all p {
    font-size: 16px;
  }

  .home .graphic-arer .container-warp {
    overflow-x: scroll;
  }

  .home .graphic-arer .graphic-items {
    flex-wrap: wrap;
    overflow-x: auto;
    width: 768px;
    position: relative;
  }

  .home .graphic-arer .shap2 {
    display: none;
  }

  .home .graphic-arer .container-warp::-webkit-scrollbar {
    display: none;
  }

  .home .graphic-arer .container-warp {
    -ms-overflow-style: none;
  }
}

/************** Store And Work Area ******************/

.home .store-work-arer {
  padding: 100px 0;
}

.home .store-work-flex {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.home .st-work-img {
  width: 50%;
}

.home .st-work-img img {
  width: 100%;
}

.home .st-work-content {
  width: 45%;
}

.home .st-work-content h2 {
  color: #fff;
}

.home .st-work-content h2 {
  font-size: 60px;
  line-height: 1.2;
  margin-bottom: 30px;
}

.home .st-work-content > p {
  color: #a8a8a8;
  font-size: 22px;
  font-weight: 300;
}

.home .st-work-content .open-source-decoder {
  font-size: 22px;
  font-weight: 300;
  color: #f77758;
  text-decoration: none;
  display: inline;
  margin: 0 0.5rem;
  transition: all 0.3s ease;
}

.home .st-work-content .open-source-decoder:hover {
  color: #ffffff;
}

@media only screen and (max-width: 1199px) {
  .home .st-work-content h2 {
    font-size: 40px;
    line-height: 1.5;
  }

  .home .st-work-content .open-source-decoder,
  .home .st-work-content > p {
    font-size: 16px;
  }

  .home .st-work-content .open-source-decoder {
    margin: 0 5px 0 5px !important;
  }
}

@media only screen and (max-width: 991px) {
  .home .st-work-content h2 {
    font-size: 36px;
  }

  .home .st-work-content .open-source-decoder,
  .home .st-work-content > p {
    font-size: 15px;
    font-weight: 300;
  }

  .home .st-work-content .open-source-decoder {
    margin: 0 4px 0 4px !important;
  }
}

@media only screen and (max-width: 767px) {
  .home .store-work-arer {
    padding: 0;
  }

  .home .store-work-arer .worp-4k {
    padding: 100px 0 60px;
  }

  .home .store-work-flex {
    flex-wrap: wrap;
    text-align: center;
  }

  .home .st-work-img {
    width: 100%;
  }

  .home .st-work-content {
    width: 100%;
  }

  .home .st-work-content h2 {
    font-size: 34px;
  }

  .home .st-work-img.img-order {
    order: -1;
    margin-top: 100px;
  }

  .home .store-work-arer .shap1 {
    top: 0;
    left: -470px;
  }

  .home .store-work-arer .shap1 img {
    width: 644px;
  }

  .home .store-work-arer .shap2 {
    position: absolute;
    z-index: -1;
    bottom: 0;
    right: -150px;
  }
}

/************** Roadmap Area ***************/

.home .roadmap-arer h2 {
  color: #fff;
  font-size: 60px;
  text-align: center;
}

.home .roadmap-flex {
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin: 60px 0;
  position: relative;
}

.home .roadmap-flex::after {
  background: linear-gradient(90deg, #f77758 28%, #000 0%);
  border-radius: 20px;
  bottom: 24px;
  content: "";
  height: 10px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.home .roadmap-flex a p {
  color: #7d90a6;
  font-size: 22px;
  text-align: center;
}

.home .roadmap-items span {
  background: #f77758 none repeat scroll 0 0;
  border-radius: 50%;
  display: block;
  height: 30px;
  margin: 30px auto 0;
  position: relative;
  text-align: center;
  width: 30px;
}

.home .roadmap-items span::after {
  background: #fff none repeat scroll 0 0;
  border-radius: 50%;
  content: "";
  height: 15px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 15px;
}

.home .roadmap-flex .roadmap-items {
  width: 16%;
}

.home .roadmap-items.btm-30 span {
  margin: 15px auto;
}
.home .strong.mo-none {
  font-weight: 400;
}

@media only screen and (max-width: 1199px) {
  .home .roadmap-arer h2 {
    font-size: 40px;
  }

  .home .roadmap-flex a p {
    color: #7d90a6;
    font-size: 14px;
    text-align: center;
  }

  .home .roadmap-items span {
    margin: 20px auto 0;
  }
}

@media only screen and (max-width: 768px) {
  .home .roadmap-flex a p {
    font-size: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .home .roadmap-arer .container-warp {
    overflow-x: scroll;
  }

  .home .roadmap-flex a p {
    font-size: 14px;
  }

  .home .roadmap-flex {
    width: 200px;
    position: relative;
    display: inherit;
    margin: 60px auto 100px 100px;
  }

  .home .roadmap-arer h2 {
    font-size: 34px;
  }

  .home .roadmap-arer .container-warp::-webkit-scrollbar {
    display: none;
  }

  .home .roadmap-items span {
    margin-right: 10px;
  }

  .home .roadmap-arer .container-warp {
    -ms-overflow-style: none;
  }

  .home .roadmap-flex .roadmap-items {
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .home .roadmap-flex::after {
    bottom: -24px;
    height: 110%;
    width: 8px;
    left: 30px;
  }

  .home .roadmap-flex a p {
    text-align: left;
    width: 130px;
    order: 2;
    margin-left: 0px;
  }

  .home a.roadmap-items.btm-30 br {
    display: none;
  }

  .home .roadmap-items.btm-30 span {
    margin: 24px auto 0;
  }

  .home .roadmap-items span {
    margin: 20px auto !important;
  }

  .home .strong.mo-none {
    display: none;
  }
}

@media only screen and (max-width: 320px) {
  .home .roadmap-flex {
    margin: 60px auto 100px 80px;
  }
}

/***************** Beginning Area ***************/

.home .beginning-arer {
  text-align: center;
}

.home .beginning-arer .worp-4k {
  padding: 100px 0;
}

.home .beginning-content h2 {
  color: #fff;
  font-size: 60px;
  font-weight: 400;
}

.home .beginning-content p {
  color: #a8a8a8;
  font-size: 22px;
  margin: 40px 0;
}

@media only screen and (max-width: 1199px) {
  .home .beginning-content h2 {
    font-size: 40px;
  }

  .home .beginning-content p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .home .beginning-content h2 {
    font-size: 34px;
    font-weight: 700;
  }

  .home .beginning-content p {
    font-size: 15px;
  }

  .home .beginning-arer .shap2 img {
    opacity: 0.4;
  }
  .home .beginning-arer .worp-4k {
    padding: 0px 0 60px !important;
  }
}

/*************** Footer Area ****************/

.home footer.beginning-arer {
  background: #f77758;
  position: relative;
  z-index: 999;
}

.home .footer-menu {
  margin: auto;
  max-width: 1045px;
}

.home .footer-menu ul li {
  display: inline-block;
}

.home .footer-menu > ul {
  align-items: center;
  display: flex;
  justify-content: space-around;
}

.home .footer-menu li a {
  color: #fff;
  display: block;
  font-size: 20px;
  text-transform: uppercase;
}

.home .footer-menu {
  margin: auto;
  max-width: 1045px;
}

.home .footer-menu ul li {
  display: inline-block;
}

.home .footer-menu > ul {
  align-items: center;
  display: flex;
  justify-content: space-around;
}

.home .footer-menu li a {
  color: #fff;
  display: block;
  font-size: 20px;
  text-transform: uppercase;
}

.home .footer-menu li a:hover {
  color: #2b2e43;
}

.home .footer-bottom {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.home .social-flaex {
  align-items: center;
  display: flex;
}

.home .social-link {
  align-items: center;
  display: flex;
  margin-left: 30px;
}

.home .social-link a {
  margin-left: 30px;
}

.home .footer-bottom p {
  color: #ffffff;
  font-size: 22px;
  opacity: 0.65;
}

@media only screen and (max-width: 991px) {
  .home footer.beginning-arer {
    padding-top: 50px;
  }

  .home .beginning-arer .worp-4k {
    padding: 50px 0 100px;
  }

  .home .beginning-content p br {
    display: none;
  }

  .home .footer-menu li a {
    font-size: 16px;
  }

  .home .footer-bottom p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .home .footer-menu > ul {
    display: inherit;
  }

  .home .footer-menu ul li {
    display: block;
  }

  .home .social-flaex {
    flex-wrap: wrap;
    justify-content: center;
  }

  .home .footer-bottom {
    flex-wrap: wrap;
    justify-content: center;
  }

  .home .social-link {
    margin: 30px auto;
  }

  .home .footer-bottom p {
    font-size: 17px;
    text-align: center;
  }

  .home .footer-menu li a {
    padding: 10px 0;
    font-weight: 300;
  }
}

@media only screen and (min-width: 2001px) {
  .home .worp-4k {
    overflow: inherit;
  }

  .home header.menu-area {
    position: fixed;
    top: 0;
  }
}

.home .worp-4k {
  position: relative;
  z-index: 99;
}

.linkButton {
  color: #ffffff;
  transition: color 0.3s linear;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  padding: 0;
}

.linkButton:hover {
  color: #f77758;
}

.linkButton.active {
  color: #f77758;
}

.profile {
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5rem;
}

.profile .loading {
  color: #ffffff;
}

.profile .container {
  display: flex;
  flex-direction: column;
  min-width: 62rem;
  justify-content: center;
  align-items: center;
}

.profile .userData {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.profile .userData .address {
  color: #f77758;
  word-break: break-all;
}

.profile .headerGallery {
  color: #ffffff;
  margin: 5rem 0;
  font-size: 1.2rem;
}

.profile .headerGallery .linkButton {
  font-size: 1.2rem;
}

.profile .gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(128px, 1fr));
  width: 100%;
  grid-gap: 1rem;
  justify-items: center;
}

.profile .gallery .pixelchain {
  width: 128px;
  height: 128px;
}

@media screen and (max-width: 1040px) {
  .profile {
    padding: 0 1rem;
  }

  .profile .container {
    min-width: 0;
    min-width: initial;
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .profile {
    flex-direction: column;
    margin-top: 2.5rem;
  }
}

@media screen and (max-width: 630px) {
  .profile .userData {
    flex-direction: column;
    align-items: center;
  }

  .profile .gallery {
    grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
  }

  .profile .gallery .pixelchain {
    width: 256px;
    height: 256px;
  }
}

